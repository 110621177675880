import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import theme from './modules/theme.js'
import userInfo from './modules/userInfo'
import snackbar from './modules/snackbar'
import dialog from './modules/dialog'
import payment from './modules/payment'
import loading from './modules/loading'
import parameters from './modules/parameters'
import pickup from './modules/pickup'
import OIDCModule from './modules/oidc' // OIDC

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
      theme,
      userInfo,
      snackbar,
      dialog,
      payment,
      loading,
      parameters,
      pickup,
      oidcStore: OIDCModule,
    },
    plugins: [createPersistedState({ // Local Storage
            reducer: (persistedState) => {
                const stateFilter = JSON.parse(JSON.stringify(persistedState)) // deep clone
                // Enlève le module 'snackbar' 'loading', 'dialog' du persistedState
                const storeToRemove = ['snackbar', 'loading', 'dialog']
                storeToRemove.forEach(item => delete stateFilter[item]) // states which we don't want to persist.
                return stateFilter
            },
        })],
})
