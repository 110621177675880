const payment = {
  strict: true,
  namespaced: true,
  state: {
    paymentId: null,
    factureId: null,
    message: null,
  },
  getters: {
  },
  mutations: {
    setPayment (state, payload) {
      state.paymentId = payload.paymentId
      state.factureId = payload.factureId
      state.message = payload.message
    },
    resetPayment (state) {
      state.paymentId = null
      state.factureId = null
      state.message = null
    },
  },
  actions: {

  },
}

export default payment
