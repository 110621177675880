export const oidcSettings = {
    authority: `${process.env.VUE_APP_OIDC_SERVER}/oauth2/auth`,
    client_id: 'gestionreseau',
    redirect_uri: `${window.location.origin}/oidc-callback/`,
    response_type: 'id_token token',
    scope: 'openid profile email roles',
    // automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 600, // (10 mins) The number of seconds before an access token is to expire to raise the accessTokenExpiring event.
    silent_redirect_uri: `${window.location.origin}/silent-renew-oidc.html`,
    post_logout_redirect_uri: `${window.location.origin}/`,
    loadUserInfo: true,
    filterProtocolClaims: true,
    metadata: {
        issuer: `${process.env.VUE_APP_OIDC_SERVER}/oauth2`,
        jwks_uri: `${process.env.VUE_APP_OIDC_SERVER}/oauth2/certs`,
        end_session_endpoint: `${process.env.VUE_APP_OIDC_SERVER}/oauth2/session/end`,
        authorization_endpoint: `${process.env.VUE_APP_OIDC_SERVER}/oauth2/auth`,
        revocation_endpoint: `${process.env.VUE_APP_OIDC_SERVER}/oauth2/token/revocation`,
        userinfo_endpoint: `${process.env.VUE_APP_OIDC_SERVER}/oauth2/userinfo`,
    },
}
/*
export const withouCorsProblem = {
  authority: 'https://api-test.dev.uqam.ca/oauth2/auth',
  client_id: 'gestionreseau',
  redirect_uri: 'https://gestionreseau.localhost:8080/oidc-callback/',
  response_type: 'id_token token',
  scope: 'openid profile email roles',
  automaticSilentRenew: true,
  silent_redirect_uri: 'https://gestionreseau.localhost:8080/silent-renew-oidc.html',
  post_logout_redirect_uri: 'https://gestionreseau.localhost:8080/',
  loadUserInfo: false,
}
*/
