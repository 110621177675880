/**
 * User Information (Mettre les infos de l'utilisateur dans ce state peut importe le système d'authentification)
 */
const userInfo = {
  strict: true,
  state: {
    info: {
      name: '',
      firstName: '',
      displayName: '',
      username: '',
      email: '',
      roles: [],
    },
    imageProfile: null,
  },

  getters: {
    getImageProfil (state) {
      return state.imageProfile
    },
  },

  mutations: {
    updateUser (state, payload) {
      state.info = payload
    },
    resetUser (state) {
      state.info = {
        name: '',
        firstName: '',
        displayName: '',
        username: '',
        email: '',
        roles: [],
      }

      state.imageProfile = null
    },
    updateImageProfil (state, payload) {
      state.imageProfile = payload
    },
  },
  actions: {

  },
}

export default userInfo
