import axios from 'axios'
import store from '@/store/index'
import Swal from 'sweetalert2'
// MSAL
import { useMsal } from '@/msal/useMsal'
import { loginRequest } from '@/config/msal'
// import { BrowserAuthError } from '@azure/msal-browser'
const router = require(`@/router/${process.env.VUE_APP_COMPANY}`).default

const errorLanguage = {
    'The request does not exist': {
        fr: 'La requête n\'existe pas',
        en: 'The request does not exist',
    },
}

export default function () {
    const api = axios.create({
        baseURL: `${process.env.VUE_APP_SERVER_ADDRESS}${process.env.VUE_APP_SERVER_ADDRESS_PATH}`,
    })
    api.interceptors.request.use(async (config) => {
        let authorization
        if (process.env.VUE_APP_AUTHENTIFICATION === 'msal') authorization = await fetchMsalAccessToken()
        if (process.env.VUE_APP_AUTHENTIFICATION === 'oidc') authorization = `Bearer ${store.state.oidcStore.access_token}`

        if (!authorization) {
          throw new axios.Cancel('Request canceled')
        }

        // Do something before request is sent
        config.headers.Authorization = authorization
        config.headers['Content-Language'] = localStorage.getItem('APP_LANG_KEY')
        return config
      }, function (error) {
        // Do something with request error
        return Promise.reject(error)
    })

    api.interceptors.response.use(response => {
      // console.log(router);
      // Do something with response data
        return response.data
    }, error => {
        // Je m'envoie un courriel de l'erreur
        if (error.response) sendEmailError(error.response)

        if (error.message) {
          if (error.message === 'Request canceled') {
              // router.push('/')
              return
          }
        }

        try {
          // Code 401 is from OpenIDConnect and Policies
          // User not unauthorized
          if (error.response.status === 401 || error.response.status === 403) {
                // Error from OpenIDConnect
                if (error.response.data.error === 'an error has occured trying to verify user access token') {
                    // Disconnect User
                    store.dispatch('oidcStore/signOutOidc')
                } else {
                    // Error from Policies
                    // Go to view route
                    router.push({
                        name: 'unauthorized',
                    })
                }
          } else if (error.response.status === 400) {
              Swal.fire({
                  icon: 'error',
                  text: error.response.data.error,
                  confirmButtonColor: '#DD6B55',
                  heightAuto: false,
              })
          } else if (error.response.status === 404) {
              // Check for translation
              const message = errorLanguage['The request does not exist'][localStorage.getItem('APP_LANG_KEY')]
              Swal.fire({
                  icon: 'error',
                  text: message,
                  confirmButtonColor: '#DD6B55',
                  heightAuto: false,
              })
          } else if (error.response.status === 500) {
              // Go to view route
              router.push({
                  name: 'error500',
              })
          }
        } catch (error) {
          console.log(error)
          // Go to view route
          router.push({
              name: 'error500',
          })
        }
        // Do something with response error
        return Promise.reject(error.response)
    })
    return api
}

// Important le catch est pas testé
const fetchMsalAccessToken = async () => {
  const { instance } = useMsal()

  const accounts = instance.getAllAccounts()
  try {
    // Récupération du token
    const token = await instance.acquireTokenSilent({
      scopes: loginRequest.scopes,
      accounts,
    })
    // Si la session n'est pas terminé on retourne le token
    return token.accessToken
  } catch (error) {
    // Si la seseion est terminé, on demande à l'utilisateur de s'authentifier
    return instance
      .acquireTokenRedirect(loginRequest)
      .then((resp) => {
        return resp.accessToken
      })
      .catch((err) => {
        console.log(err)
        return null
      })
  }
}

const sendEmailError = async (error) => {
    try {
        let authorization
        if (process.env.VUE_APP_AUTHENTIFICATION === 'msal') authorization = await fetchMsalAccessToken()
        if (process.env.VUE_APP_AUTHENTIFICATION === 'oidc') authorization = `Bearer ${store.state.oidcStore.access_token}`

        error.time = new Date().toLocaleString('en-US', { timeZone: 'America/Montreal' })
        error.user = store.state.userInfo.info.mail

        let to = ['steven.pare@01solutions.ca', 'jacques.mercure@01solutions.ca']
        if (process.env.NODE_ENV === 'development') to = ['steven.pare@01solutions.ca']

        const mailOptions = {
            to: to,
            subject: `Erreur http venant du backend (${process.env.NODE_ENV})`,
            html: `Erreur http venant du backend (${process.env.NODE_ENV})`,
            attachments: [
                {
                    filename: 'Http_erreur.json',
                    content: JSON.stringify(error, null, 2),
                },
            ],
        }

        axios.post(`${process.env.VUE_APP_SERVER_ADDRESS}${process.env.VUE_APP_SERVER_ADDRESS_PATH}/mailer`,
            mailOptions,
            {
                headers:
              {
                  Authorization: authorization,
                  'Content-Language': localStorage.getItem('APP_LANG_KEY'),
              },
            })
    } catch (error) {
        console.error(error)
    }
}

// import axios from 'axios'
// import store from '@/store/index'
// import Swal from 'sweetalert2'
// // MSAL
// import { useMsal } from '@/msal/useMsal'
// import { loginRequest } from '@/config/msal'
// import { InteractionRequiredAuthError } from '@azure/msal-browser'
// const router = require(`@/router/${process.env.VUE_APP_COMPANY}`).default

// const errorLanguage = {
//     'The request does not exist': {
//         fr: 'La requête n\'existe pas',
//         en: 'The request does not exist',
//     },
// }

// export default function () {
//     const api = axios.create({
//         baseURL: `${process.env.VUE_APP_SERVER_ADDRESS}${process.env.VUE_APP_SERVER_ADDRESS_PATH}`,
//         headers: {
//             'Content-Language': localStorage.getItem('APP_LANG_KEY'),
//         },
//     })

//     api.interceptors.request.use(async (config) => {
//         let authorization
//         if (process.env.VUE_APP_AUTHENTIFICATION === 'msal') authorization = await fetchMsalAccessToken()
//         if (process.env.VUE_APP_AUTHENTIFICATION === 'oidc') authorization = `Bearer ${store.state.oidcStore.access_token}`
//         // Do something before request is sent
//         config.headers.Authorization = authorization
//         config.headers['Content-Language'] = localStorage.getItem('APP_LANG_KEY')
//         return config
//       }, function (error) {
//         // Do something with request error
//         return Promise.reject(error)
//       })

//     api.interceptors.response.use(response => {
//     // console.log(router);
//     // Do something with response data
//         return response.data
//     }, error => {
//         try {
//           // Code 401 is from OpenIDConnect and Policies
//           // User not unauthorized
//           if (error.response.status === 401 || error.response.status === 403) {
//             // Error from OpenIDConnect
//                 if (error.response.data.error === 'an error has occured trying to verify user access token') {
//                     // Disconnect User
//                     store.dispatch('oidcStore/signOutOidc')
//                 } else {
//                     // Error from Policies
//                     // Go to view route
//                     router.push({
//                         name: 'unauthorized',
//                     })
//                 }
//           } else if (error.response.status === 400) {
//               Swal.fire({
//                   icon: 'error',
//                   text: error.response.data.error,
//                   confirmButtonColor: '#DD6B55',
//                   heightAuto: false,
//               })
//           } else if (error.response.status === 404) {
//               // Check for translation
//               const message = errorLanguage['The request does not exist'][localStorage.getItem('APP_LANG_KEY')]
//               Swal.fire({
//                   icon: 'error',
//                   text: message,
//                   confirmButtonColor: '#DD6B55',
//                   heightAuto: false,
//               })
//           } else if (error.response.status === 500) {
//               // Go to view route
//               router.push({
//                   name: 'error500',
//               })
//           }
//         } catch (error) {
//           console.log(error)
//           // Go to view route
//           router.push({
//               name: 'error500',
//           })
//         }
//         // Do something with response error
//         return Promise.reject(error.response)
//     })
//     return api
// }

// // function fetchMsalAccessToken () {
// //     const { instance, accounts, inProgress } = useMsal()
// //     console.log(accounts)
// //     console.log(instance)
// //     console.log(inProgress)

// //  return new Promise((resolve) => {
// //  instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] })
// //            .then((response) => { resolve(response.accessToken) })
// //     })
// // }

// // async function fetchMsalAccessToken () {
// //     const { instance } = useMsal()
// //     const response = await instance.acquireTokenSilent({
// //       ...loginRequest,
// //     }).catch(async (e) => {
// //       if (e instanceof InteractionRequiredAuthError) {
// //         await instance.acquireTokenRedirect(loginRequest)
// //       }
// //       throw e
// //     })
// //     return response.accessToken
// // }

// // Important le catch est pas testé
// const fetchMsalAccessToken = async () => {
//   const { instance } = useMsal()
//   try {
//     const token = await instance.acquireTokenSilent({
//       ...loginRequest,
//     })
//     return token.accessToken
//   } catch (error) {
//     if (error instanceof InteractionRequiredAuthError) {
//       return instance
//         .acquireTokenRedirect(loginRequest)
//         .then((resp) => {
//           return resp.accessToken
//         })
//         .catch((err) => {
//            // Go to view route
//           router.push({
//               name: 'Login',
//           })
//           console.log(err)
//         })
//     } else {
//           // Go to view route
//           router.push({
//               name: 'Login',
//           })
//       console.log(error)
//     }
//   }
// }
